import { atom } from "jotai";

import type { Props } from "scmp-app/components/advertisement/ad-slots/ad-slot";

type HeaderAdSlotSpec = Pick<Props, "adUnit" | "targeting" | "zone" | "autoRefreshOptions"> & {
  sizes?: googletag.GeneralSize;
};
export const topBannerAdSlotAtom = atom<AdSlotState>(null);
export type AdSlotState = {
  desktop: HeaderAdSlotSpec | null;
  mobile: HeaderAdSlotSpec | null;
} | null;
