import { FollowType } from "@product/scmp-sdk";

import type { FollowListKeys } from "./types";

export const FollowTypeKeyMapping: Record<string, FollowListKeys> = {
  [FollowType.Author]: "authors",
  [FollowType.Section]: "sections",
  [FollowType.Topic]: "topics",
};
export const UserFollowSwrFetchKey = "/user-follow/user-follow-list";
export const UserFollowV1Key = "UserFollowList";
export const LastSyncStorageKey = "UserFollowListLastUpdateTime"; // Key from v1 refs: https://project.scmp.tech/product/web/scmp-pwa/-/blob/master/scmp-app/src/universal/utils/user/index.ts#L105
