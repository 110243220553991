import { theme, useFallbackStorageValue, useResponsive } from "@product/scmp-sdk";
import { useAsync, useDeepCompareEffect, useMountEffect } from "@react-hookz/web";
import merge from "deepmerge";
import { useAtomValue } from "jotai";
import defaults from "lodash/defaults";
import { useMemo, useState } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import type { AppBarConfiguration } from "scmp-app/components/app-bar/types";
import { config as configData } from "scmp-app/data";
import type { hooksAdvertisementConfigQuery } from "scmp-app/queries/__generated__/hooksAdvertisementConfigQuery.graphql";

import { DynamicAdSlot } from "./ad-slots/ad-slot/dynamics";
import { topBannerAdSlotAtom } from "./ad-slots/top-banner-ad-slot/atoms";

export const useAdvertisementConfig = () => {
  const { set: setCachedConfig, value: cachedConfig } =
    useFallbackStorageValue<RawAdvertisementConfig>("advertisement-config");

  const [config, setConfig] = useState<AdvertisementConfig>(
    parseConfig(cachedConfig) ?? defaultAdvertisementConfig,
  );
  const environment = useRelayEnvironment();
  const [{ result: remoteConfig, status }, { execute }] = useAsync(async () => {
    // Set config in drupal
    // Ref: https://${drupal-host}/admin/config/services/app-config
    // Example: https://staging-scmp-d8.product-web.dev-2.scmp.tech/admin/config/services/app-config
    const data = await fetchQuery<hooksAdvertisementConfigQuery>(
      environment,
      graphql`
        query hooksAdvertisementConfigQuery($id: String) {
          appConfig(filter: { entityId: $id }) {
            json
          }
        }
      `,
      {
        id: configData.advertisement.configId,
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    return data.appConfig.json as RawAdvertisementConfig;
  });
  useMountEffect(execute);

  useDeepCompareEffect(() => {
    if (!remoteConfig) return;

    setConfig(parseConfig(remoteConfig) ?? defaultAdvertisementConfig);
    setCachedConfig(remoteConfig);
  }, [remoteConfig, setCachedConfig]);

  return { config, loaded: status !== "loading" && status !== "not-executed" };
};

export type AdvertisementConfig = {
  autoRefresh: {
    desktopMaximumRefreshLimit: number;
    disabledCountries: string[];
    minimalScrollAwayDurationMs: number;
    mobileMaximumRefreshLimit: number;
    refreshIntervalMs: number;
  };
  viewabilityTracking: {
    enable: boolean;
    eventIntervalMs: number;
    testMode: {
      enable: boolean;
      targetUrlPatterns: string[];
    };
    workerIntervalMs: number;
  };
};

export const defaultAdvertisementConfig: AdvertisementConfig = {
  autoRefresh: {
    desktopMaximumRefreshLimit: 30,
    disabledCountries: [],
    minimalScrollAwayDurationMs: 5000,
    mobileMaximumRefreshLimit: 5,
    refreshIntervalMs: 20_000,
  },
  viewabilityTracking: {
    enable: false,
    eventIntervalMs: 10_000,
    testMode: {
      enable: false,
      targetUrlPatterns: [],
    },
    workerIntervalMs: 15_000,
  },
};

type RawAdvertisementConfig = {
  adViewabilityTrackingEnable?: number;
  adViewabilityTrackingEventInterval?: number;
  adViewabilityTrackingQueueWorkerInterval?: number;
  adViewabilityTrackingTargetUrls?: string[];
  adViewabilityTrackingTestMode?: number;
  autoRefreshDesktopLimit?: number;
  autoRefreshInterval?: number;
  autoRefreshMinimalScrollAwayDurationMs?: number;
  autoRefreshMobileLimit?: number;
  disableAutoRefreshCountries?: string[];
};

function parseConfig(raw?: RawAdvertisementConfig | null): AdvertisementConfig | undefined {
  if (!raw) return;

  const parsed: DeepPartial<AdvertisementConfig> = {
    autoRefresh: {
      desktopMaximumRefreshLimit: raw.autoRefreshDesktopLimit,
      disabledCountries: raw.disableAutoRefreshCountries,
      minimalScrollAwayDurationMs: raw.autoRefreshMinimalScrollAwayDurationMs,
      mobileMaximumRefreshLimit: raw.autoRefreshMobileLimit,
      refreshIntervalMs: raw?.autoRefreshInterval && raw.autoRefreshInterval * 1000,
    },
    viewabilityTracking: {
      enable: raw.adViewabilityTrackingEnable === 1,
      eventIntervalMs: raw.adViewabilityTrackingEventInterval,
      testMode: {
        enable: raw.adViewabilityTrackingTestMode === 1,
        targetUrlPatterns: raw.adViewabilityTrackingTargetUrls,
      },
      workerIntervalMs: raw.adViewabilityTrackingQueueWorkerInterval,
    },
  };

  const merged = {
    autoRefresh: defaults(parsed.autoRefresh, defaultAdvertisementConfig.autoRefresh),
    viewabilityTracking: defaults(
      parsed.viewabilityTracking,
      defaultAdvertisementConfig.viewabilityTracking,
    ),
  };

  return merged;
}
const defaultAdSlotVariants = {
  large: {
    height: 250,
    padding: 20,
  },
  small: {
    height: 100,
    padding: 10,
  },
} as const;
const styleAdSlotVariants = merge(defaultAdSlotVariants, {
  small: {
    padding: 20,
  },
});
export const useResponsiveAdSlot = (configuration?: AppBarConfiguration) => {
  const adSlotValue = useAtomValue(topBannerAdSlotAtom);

  const adSlotVariants = {
    ...(configuration?.variant === "scmp/magazines-style"
      ? styleAdSlotVariants
      : defaultAdSlotVariants),
    ...configuration?.adSlotVariants,
  };

  const bindAdSlotContainer = useMemo(() => {
    const largeStyles = {
      $height: adSlotVariants.large.height,
      $isVisible: configuration?.hasDesktopAd,
      $padding: adSlotVariants.large.padding,
    };
    const smallStyles = {
      $height: adSlotVariants.small.height,
      $isVisible: configuration?.hasMobileAd,
      $padding: adSlotVariants.small.padding,
    };

    switch (configuration?.variant) {
      case "scmp/home":
        return {
          $responsiveVariants: {
            desktopUp: smallStyles,
            homeDesktopUp: largeStyles,
            mobileUp: smallStyles,
            tabletUp: smallStyles,
          },
        };
      default:
        return {
          $responsiveVariants: {
            desktopUp: largeStyles,
            mobileUp: smallStyles,
            tabletUp: smallStyles,
          },
        };
    }
  }, [
    adSlotVariants.large.height,
    adSlotVariants.large.padding,
    adSlotVariants.small.height,
    adSlotVariants.small.padding,
    configuration?.hasDesktopAd,
    configuration?.hasMobileAd,
    configuration?.variant,
  ]);

  const isDesktopUp = useResponsive(
    theme.breakpoints.up(configuration?.desktopBreakpoint ?? "desktop"),
  );
  const { adSlotHeight, responsiveAdSlot, stickyStyle } = useMemo(() => {
    switch (true) {
      case isDesktopUp:
        return {
          adSlotHeight: adSlotValue?.desktop
            ? adSlotVariants.large.height + adSlotVariants.large.padding * 2
            : 0,
          responsiveAdSlot: adSlotValue?.desktop ? (
            <DynamicAdSlot
              sizes={[
                [970, 250],
                [728, 90],
                [970, 90],
              ]}
              {...adSlotValue.desktop}
            />
          ) : null,
          stickyStyle: "withScrollAwayElement" as const,
        };
      default:
        return {
          adSlotHeight: adSlotValue?.mobile
            ? adSlotVariants.small.height + adSlotVariants.small.padding * 2
            : 0,
          responsiveAdSlot: adSlotValue?.mobile ? (
            <DynamicAdSlot
              sizes={[
                [300, 100],
                [320, 100],
                [300, 50],
                [320, 50],
              ]}
              {...adSlotValue.mobile}
            />
          ) : null,
          stickyStyle: "withHideElementAfterScrollThreshold" as const,
        };
    }
  }, [
    adSlotValue?.desktop,
    adSlotValue?.mobile,
    adSlotVariants.large.height,
    adSlotVariants.large.padding,
    adSlotVariants.small.height,
    adSlotVariants.small.padding,
    isDesktopUp,
  ]);

  return {
    adSlotHeight,
    bindAdSlotContainer,
    responsiveAdSlot,
    stickyStyle,
  };
};
