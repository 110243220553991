import { FollowButtonAction, FollowType, PageType, unionArray } from "@product/scmp-sdk";

import type {
  FollowEvent,
  TriggerPoint,
} from "scmp-app/components/tracking/google-analytics-4/types";

import { FollowTypeKeyMapping } from "./consts";
import type { FollowButtonSource, FollowPayload, GetUserFollowList } from "./types";

export const generateNewList = (
  previousFollowList: GetUserFollowList["userFollowList"] | undefined,
  payload: FollowPayload,
) => {
  const { action, ids, type } = payload;
  const newFollowList = previousFollowList
    ? { ...previousFollowList }
    : { authors: [], sections: [], topics: [] };
  const typeName = FollowTypeKeyMapping[type];
  if (action === FollowButtonAction.Follow) {
    newFollowList[typeName] = unionArray(newFollowList[typeName], ids);
  } else {
    const unfollowEntityIds = new Set(ids);
    newFollowList[typeName] = newFollowList[typeName].filter(
      entityId => !unfollowEntityIds.has(entityId),
    );
  }
  return newFollowList;
};

type ComputeGa4CustomizedParametersArguments = {
  currentPageType: PageType;
  entityIds: string | string[];
  source?: FollowButtonSource;
  type: FollowType;
};

export function computeGa4CustomizedParameters({
  currentPageType,
  entityIds,
  source,
  type,
}: ComputeGa4CustomizedParametersArguments): Omit<
  FollowEvent["customized_parameters"],
  "action_type" | "package_name" | "user_type"
> {
  return {
    ...(type === FollowType.Author ? { author_id: entityIds[0] } : {}),
    ...(type === FollowType.Section ? { section_id: entityIds[0] } : {}),
    ...(type === FollowType.Topic ? { topic_id: entityIds[0] } : {}),
    follow_type: type,
    internal_campaign: "",
    trigger_point: getGa4TriggerPoint({ currentPageType, source }),
  };
}

type GetGa4TriggerPointArguments = {
  currentPageType: PageType;
  source?: FollowButtonSource;
};

const getGa4TriggerPoint = ({ currentPageType, source }: GetGa4TriggerPointArguments) => {
  if (currentPageType === PageType.Homepage) {
    return "homepage_click";
  }

  switch (source) {
    case "Article_topic_footer":
      return "article_bottom";

    case "Trending Topics":
      return "trending_topics";

    case "Section":
    case "Article_top":
    case "Article_bottom":
    case "Topic":
    case "Author":
    case "Homepage_click":
    case "Trending_topics":
      return source.toLowerCase() as TriggerPoint;

    default:
      return source;
  }
};
